@tailwind base;
@tailwind components;
@tailwind utilities;


.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  margin-top: 12px;
  margin-bottom: 8px;
}

.ProseMirror p {
  margin-top: 8px;
  margin-bottom: 8px;
}

.ProseMirror ul,
.ProseMirror ol,
.ProseMirror li,
.ProseMirror li p {
  margin: 0;
}

.ProseMirror .node-task p {
  margin: 0;
}

.ProseMirror hr {
  margin-top: 2em;
  margin-bottom: 2em;
}

.ProseMirror a {
  cursor: pointer;
}

.ProseMirror p.is-editor-empty:first-child::before,
.ProseMirror p.is-empty:before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.toolbar {
  -webkit-app-region: drag;
}